@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.login-main {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.data {
  position: absolute;
  top: 20%;
  width: 70%;
  height: 10%;
  color: #fff;
  left: 16%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}

.login-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #263448;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  opacity: 0.84;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.06);
  border-radius: 0px 50px 50px 0px;
  box-sizing: border-box;
  padding: 5%;
}

.login-signin {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.04em;
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.9;
}

.login-input {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 3px rgba(34, 30, 30, 0.28),
    inset 0px 3px 3px rgba(255, 253, 253, 0.25);
  border-radius: 12px;
  display: block;
  margin: 2%;
  width: 25rem;
  height: 3.5rem;
  padding-left: 13px;
  color: #fff;
}

.login-input::placeholder {
  padding: auto;
  color: #fff;
  font-size: 1.2rem;
}
.login-input[type="text"] {
  font-size: 20px;
}

.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(53, 71, 97, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.06);
  border-radius: 50px 0 0 50px;
  margin: 0;
  padding: 25px;
}

/* #login-button {
  background: linear-gradient(
    180deg,
    rgba(0, 255, 37, 0.5) 0%,
    #000000 99.99%,
    rgba(54, 165, 70, 0.5) 100%
  );
  border: 0px solid rgba(140, 255, 0, 0.15);
  border-radius: 20px;
  margin: 5%;
  margin-left: 2rem;
  width: 25rem;
  height: 3.5rem;
  padding-left: 13px;
  color: white;
  font-size: 20px;
  font: Mulish;
} */
#send-otp-button {
  background: linear-gradient(
    180deg,
    rgba(0, 255, 37, 0.5) 0%,
    #000000 99.99%,
    rgba(54, 165, 70, 0.5) 100%
  );
  border: 0px solid rgba(140, 255, 0, 0.15);
  border-radius: 20px;
  margin: 4%;
  /* margin-left: 2rem; */
  width: 8rem;
  height: 3.5rem;
  padding-left: 10px;
  color: white;
  font-size: 20px;
  font: Mulish;
}
#resend-otp-button {
  background: transparent;
  border: 0px solid rgba(255, 21, 0, 0.15);
  border-radius: 20px;
  /* margin: 5%; */
  /* margin-left: 2rem; */
  /* width: 5rem;*/
  height: 2rem; 
  padding-left: 10px;
  color: white;
  font-size: 20px;
  font: Mulish;
}

.moptros {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 0.6rem;
  position: absolute;
  bottom: 4%;
  justify-items: center;
  align-items: baseline;
}

.vehicle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;
  gap: 5px;
}

form > a {
  margin-right: 2rem;
  padding: 0;
  text-decoration: none;
}

.vehicle img {
  width: 80%;
}

h5 {
  padding-top: 5px;
}
/* .fp {
    display: flex;
    padding-right: 17rem;
} */
button:hover {
  cursor: pointer;
}

#warning {
  margin-right: 20%;
  width: 40%;
  background: none;
  border: none;
}

#warning::placeholder {
  color: red;
}
.data {
  color: white;
}

#main-alert-box {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  right: 0;
  background: rgb(0, 0, 0, 0.4);
  z-index: 15;
}

.loader {
  position: absolute;
  display: block;
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
content: '';
display: block;
position: absolute;
inset: 0;
border-radius: 50%;
transform: rotateX(70deg);
animation: 1s spin linear infinite;
}
.loader:after {
color: #44d1c8;
transform: rotateY(70deg);
animation-delay: .4s;
}

.login-input:focus {
  background-color: #1b3643;
}

@keyframes rotate {
0% {
transform: translate(-50%, -50%) rotateZ(0deg);
}
100% {
transform: translate(-50%, -50%) rotateZ(360deg);
}
}

@keyframes rotateccw {
0% {
transform: translate(-50%, -50%) rotate(0deg);
}
100% {
transform: translate(-50%, -50%) rotate(-360deg);
}
}

@keyframes spin {
0%,
100% {
box-shadow: .2em 0px 0 0px currentcolor;
}
12% {
box-shadow: .2em .2em 0 0 currentcolor;
}
25% {
box-shadow: 0 .2em 0 0px currentcolor;
}
37% {
box-shadow: -.2em .2em 0 0 currentcolor;
}
50% {
box-shadow: -.2em 0 0 0 currentcolor;
}
62% {
box-shadow: -.2em -.2em 0 0 currentcolor;
}
75% {
box-shadow: 0px -.2em 0 0 currentcolor;
}
87% {
box-shadow: .2em -.2em 0 0 currentcolor;
}
}

.login-heading {
  background-color: rgb(0, 0, 0, 0.6);
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: 800;
  position: absolute;
  top: 0;
}

.login-heading img {
  position: absolute;
  z-index: 5;
  left: 1%;
  /* width:; */
}

.mob-login-logo {
  display: none;
}

.forget-password {
  color: #27a13f;
  cursor: no-drop;
  padding-top: 2%;
}

@media (max-width: 600px) {
  .forget-password {
    color: #27a13f;
    cursor: no-drop;
    font-size: 0.8rem;
  }
  .login-heading img {
    width: 10%;
  }
  .login-heading {
    font-size: 1rem;
  }
  .login-logo {
    display: none;
  }
  .mob-login-logo {
    /* position: absolute; */
    top: 9%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    background: rgba(53, 71, 97, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.06);
    border-radius: 15px 15px 0px 0px;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .vehicle img {
    width: 80%;
  }
  .data {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 70%;
    left: 0;
    top: 10%;
  }
  .sign-in {
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.06);
    border-radius: 0px 0px 15px 15px;
    font-size: 1rem;
    width: 90%;
    height: 60%;
    justify-content: center;
    gap: 0.5rem;
  }
  .login-signin {
    font-size: 1.2rem;
    opacity: 1;
    font-weight: bold;
  }
  .login-input::placeholder {
    font-size: 1rem;
  }
  .login-input {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 3px rgba(34, 30, 30, 0.28),
    inset 0px 3px 3px rgba(255, 253, 253, 0.25);
    border-radius: 12px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 2%;
    width: 79%;
    height: 2rem;
    padding-left: 5px;
    color: #fff;
    font-size: 1rem;
    padding-left: 2%;
  }
  .login-input[type="text"] {
    font-size: 1rem;
  }

  #login-button {
    box-shadow: none;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2%; */
    width: 30%;
    height: 2.2rem;
    color: #fff;
    font-size: 1rem;
  }
  .moptros {
    /* grid-template-columns: repeat(2, 1fr); */
    row-gap: 0rem;
    bottom: 3%;
    opacity: 0.7;
    width: 90%;
  }
  .vehicle {
    gap: 0;
  }
  .mob-login-title {
    font-size: 1.2rem;
  }
}

