@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:wght@300&display=swap");

.dashboard-main {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  color: white;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
}

.dash-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
  margin-top: 1.5rem;
}

.dash-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #d9d9d9;
  margin-top: 1rem;
}
