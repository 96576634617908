.foot {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0, 0.47);
  color: white;
  /* opacity: 0.47; */
  overflow: hidden;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-text {
  opacity: 0.8;
}
@media (max-width: 600px) {
  .footer-text {
    font-size: 0.5rem;
  }
  .foot {
    background-color: inherit;
    height: auto;
  }
  .footer-text {
    opacity: 0.5;
  }
}
