.parameters-report {
  color: white;
  margin-top: 0px;
}

.parameter {
  color: white;
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  margin-top: 0px;
}

.parameterdata {
  color: white;
  margin-left: 1rem;
  text-align: center;
}

.form-inputs-wide {
  width: 80%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  color: white;
  row-gap: 1.5rem;
  column-gap: 0rem;
  margin-left: 3rem;
  /* justify-content: center; */
}
