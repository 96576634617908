@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.main-form {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5%;
}

.form-body-wide {
  height: 85%;
  width: 60%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
  row-gap: 1rem;
}

.form-body {
  height: 85%;
  width: 60%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
  row-gap: 0rem;
}

.heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.form-inputs {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* justify-items: center; */
  align-items: center;
  color: white;
  row-gap: 1.5rem;
  column-gap: 1rem;
}

.parameters-report {
  color: white;
  margin-top: 0px;
}

.ant-input {
  background-color: #1b3643;
  border: none;
  /* height: 2rem; */
  color: white;
}

.ant-input::placeholder {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.sbt-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 15%;
  height: 2.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  opacity: 0.8;
}

.ant-input-affix-wrapper {
  background-color: #1b3643;
  border: none;
  height: 2rem;
}

.ant-input-affix-wrapper > input.ant-input {
  background-color: #1b3643;
}

input[type="password"]:focus {
  background-color: #1b3643;
  outline: 0;
}

.form-select {
  font-family: "Montserrat", sans-serif;
  background-color: #1b3643;
  border-radius: 5px;
  color: white;
}

.parameter-select {
  font-family: "Montserrat", sans-serif;
  background-color: #1b3643;
  border-radius: 5px;
  color: white;
  width: 22rem;
}

.gen-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 50%;
  height: 2rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  opacity: 0.8;
  font-size: 0.8rem;
  margin: 0;
}

span {
  font-family: "Montserrat", sans-serif;
  color: white;
}

.parameterdata {
  color: white;
  margin-left: 1rem;
  text-align: center;
}

.parameter {
  color: white;
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  margin-top: 0px;
}

.ant-select-selector {
  background-color: #1b3643 !important;
  border: none;
  color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  border: none;
  border-radius: 5px;
}

.form-select .ant-select-selector {
  border-radius: 5px;
  height: auto;
  background-color: #1b3643;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 2rem;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: white;
  opacity: 0.8;
}

.ant-picker .ant-picker-input > input::placeholder {
  color: white;
}

textarea {
  background-color: #1b3643;
  color: white;
  resize: vertical;
  height: 4rem;
  border: none;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  padding: 5px;
  padding-left: 1rem;
}

textarea::placeholder {
  font-family: "Montserrat", sans-serif;
  color: white;
  opacity: 0.8;
}

.ant-picker .ant-picker-input > input {
  color: white;
  border: none;
  width: 50%;
  font-family: "Montserrat", sans-serif;
  /* padding-left: 0.5rem; */
}

.ant-input[disabled] {
  background-color: #1b3643;
}

.imp {
  color: rgb(226, 67, 67);
  font-size: large;
}

.ant-input-number .ant-input-number-input {
  color: white;
  font-family: "Montserrat", sans-serif;
  background-color: #1b3643;
  border: none;
}

.ant-input-number .ant-input-number-input::placeholder {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
  background-color: #1b3643;
  color: white;
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: white;
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #00b7a8;
}

.ant-btn-primary:disabled {
  color: white;
  opacity: 0.5;
}

.ant-picker-dropdown .ant-picker-time-panel .ant-picker-content {
  background-color: #1b3643;
}

@media (max-width: 1100px) {
  .main-form {
    top: 7%;
  }

  /* h2 {
      font-size: 1rem;
    } */
  .form-body {
    row-gap: 0.8rem;

    /* font-size: 0.8rem; */
  }

  textarea {
    height: 3rem;
  }

  .sbt-btn {
    font-size: 0.8rem;
    margin: 0;
  }
}

@media (max-height: 700px) {
  .main-form {
    top: 7%;
  }

  /* h2 {
    font-size: 1rem;
  } */
  .form-body {
    row-gap: 0.8rem;
    /* font-size: 0.8rem; */
  }

  textarea {
    height: 3rem;
  }

  .sbt-btn {
    font-size: 0.8rem;
    margin: 0;
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: white;
}

button {
  border: none;
}

.vin-gen-heading {
  width: 100%;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.vin-gen-sub-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0.5rem 0;
  font-weight: 400;
  opacity: 0.5;
  cursor: pointer;
}

.vin-gen-sub-heading:hover {
  border-bottom: 2px solid #5df1e5;
  opacity: 1;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f4ff24;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: #e6f4ff26;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: #e6f4ff21;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  inset-inline-end: 17%;
  border-top-right-radius: 15%;
  border-bottom-right-radius: 15%;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  inset-inline-start: 16%;
  /* inset-inline-end: 17%; */
  border-top-left-radius: 15%;
  border-bottom-left-radius: 15%;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f4ff21;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end::before:hover {
  background-color: transparent !important;
}

.ant-picker .ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  color: inherit;
  font-size: 14px;
  line-height: 1.5714285714285714;
  transition: all 0.2s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
  font-family: inherit;
}

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
  background-color: transparent !important;
}

.ant-picker-range:hover .ant-picker-suffix:not(:last-child) {
  opacity: 0;
}

.ant-picker-dropdown .ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.88);
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #1677ff;
}

.ant-picker-cell-inner:hover {
  background-color: transparent !important;
}
