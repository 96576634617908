.tglBtnEnable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 75%;
    height: 2.5rem;
    color: white;
    font-family: "Lato", sans-serif;
    font-family: "Montserrat", sans-serif;
    background: linear-gradient(180deg, #F3901C 0%, #CA6D00 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    opacity: 0.8;
}

.tglBtnDisable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 75%;
    height: 2.5rem;
    color: black;
    font-family: "Lato", sans-serif;
    font-family: "Montserrat", sans-serif;
    background: linear-gradient(180deg, #ffffff 0%, #D1D1D1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    opacity: 0.8;
}

.unselected {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 75%;
    height: 2.5rem;
    color: rgb(28, 28, 28);
    font-family: "Lato", sans-serif;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    background: linear-gradient(180deg, #9a9a9a 0%, #7f7f7f 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    opacity: 0.8;
}