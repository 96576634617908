.info-div {
    position: absolute;
    height: 80%;
    top: 12%;
    left: 13%;
    width: 80%;
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    flex-direction: column;
    align-items: center;
}

.info-errorlogs {
    position: absolute;
    height: 80%;
    top: 12%;
    left: 13%;
    width: 77%;
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    flex-direction: column;
    align-items: center;
    opacity: 0.3
}

.info-heading {
    position: absolute;
    top: 2%;
    width: 100%;
    display: flex;
    font-weight: bold;
    justify-content: center;
    font-size: larger;
    /* font-weight: 400; */
    margin-left: 30px;
}

.info-form {
    width: 75vw;
    height: 50vh;
    background: rgb(0, 0, 0, 0.8);
    padding-top: 2%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 0%;
    padding-bottom: 4%;
}

.info-report {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 0.8fr 1fr 1fr 1fr 1fr;
    /* width: 100%; */
    font-size: small;
    height: 17%;
    align-items: center;
}

.body-report {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 0.8fr 1fr 1fr 1fr 1fr;
    /* width: 100%; */
    font-size: small;
    height: 20%;
    align-items: center;
    font-weight: bold;
    font-size: 0.8rem;
    background-color: #15202f;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.notok-div {
    position: fixed;
    height: 80%;
    top: 12%;
    left: 13%;
    width: 77%;
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    flex-direction: column;
    align-items: center;
}

.ok {
    color: green;
    font-weight: 800;
    font-size: small;
}

.notOk {
    color: red;
    font-weight: 800;
    font-size: small;
}

.dashboard-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;

}
.filter{
    color: white;
    background-color: #15202f;
    height: 2rem;
    width: 5rem;
    border: none;
    border-radius:0.5rem;
}
.data-vinnumber {
    font-size: small;
    cursor: pointer;
    color: #00c0ff;
}

.eventlogs-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    color: white;
}

.eventlogs-data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-right: 2rem;
    color: white;
}

.data-vinnumber:hover {
    text-decoration: underline;
}

.live-report {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 0.8fr 1fr 1fr 1fr 1fr;
    font-size: small;
    height: 17%;
    white-space: normal;
    align-items: center;
}

.eventlogs-submit {
    background-color: lightblue;
    border-radius: 0.5rem;
    margin-left: 2rem;
}

.data-customer {
    margin-left: 1rem;
    width: 100px;
    word-wrap: break-word;
    font-size: small;
}

.data-location {
    width: 100px;
    word-wrap: break-word;
    font-size: small;
}

.data-vin {
    font-size: small;
}

.heading {
    margin-left: 0.1rem;
}

.password {
    margin-left: 0.2rem;
}

.eventlogs-popup {
    height: 50%;
    margin-top: 1rem;
    width: 30%;
    top: 26%;
    display: flex;
    position: fixed;
    z-index: 100;
    border-radius: inherit;
    background-color: black;
    left: 35%;
    right: 28%;
    top: 25%;
    flex-direction: column;
    gap: 4rem;
    padding-left: 2%;
    border-radius: 1rem;
}

.cross {
    position: absolute;
    top: 5%;
    right: 2%;
    width: 8%;
    height: 10%;
}

.cross:hover {
    cursor: pointer;
}

::-webkit-scrollbar {
    height: 0.2rem;
    width: 0.2rem;
    /* background: #00c0ff; */
}

::-webkit-scrollbar-thumb {
    /* background: #393812; */
    background:rgb(126, 124, 124);
    -webkit-border-radius: 1rem;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}