@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Source+Sans+Pro:wght@300&display=swap");

.vin {
  position: absolute;
  left: 10.88%;
  right: 60.48%;
  top: 14.1%;
  bottom: 56.29%;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.data-one {
  position: absolute;
  left: 10.93%;
  right: 60.42%;
  top: 45.9%;
  bottom: 10.63%;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
  font-family: "Lato", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
}

.data-two {
  position: absolute;
  left: 41.3%;
  right: 5.16%;
  top: 14.1%;
  bottom: 49.26%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}

.data-three {
  position: absolute;
  left: 41.3%;
  right: 5.16%;
  top: 52.73%;
  bottom: 10.63%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}

.vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.open-vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px 15px 0px 0px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

.vehicle-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 82%;
  height: 2.5rem;
  /* background: #1b3643; */
  /* border-radius: 15px; */
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.open-vehicle-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 82%;
  /* height: 2.5rem;
  background: #1b3643; */
  border-radius: 15px 15px 0px 0px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

li {
  list-style: none;
}

.vin-submit {
  background-color: #00b7a8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 40%;
  height: 2.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  opacity: 0.8;
}

.vin-submit:hover {
  opacity: 1;
}

.ind-dropdown-menu {
  position: absolute;
  width: 82%;
  left: 9%;
  top: 35%;
  background: #1b3643;
  opacity: 1;
  border-radius: 0px 0px 4px 4px;
  z-index: 15;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: 7.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  color: white;
  margin: 0;
  border-radius: 0px 0px 15px 15px;
  padding-left: 0;
}

.ind-dropdown-container {
  width: 100%;
}

.dropdown-container {
  position: relative;
  width: 200px;
}

.dropdown-header {
  padding-left: 3rem;
  padding-right: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
  padding-left: 3rem;
}

.dropdown-option:hover {
  background-color: rgba(29, 152, 152, 0.29);
}

.dropdown-option.selected {
  background-color: rgba(29, 152, 152, 0.29);
}

.dropdown-title {
  padding-right: 10rem;
  display: contents;
}

.data-one {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.vin-submit:hover {
  cursor: pointer;
}

.slider {
  display: flex;
  justify-content: center;
  gap: 50%;
  align-items: center;
  position: absolute;
  top: 90%;
  /* bottom: 12.82%; */
  /* height: 1rem; */
  width: 20%;
  background: rgba(29, 152, 152, 0.29);
  border-radius: 10px;
  height: 1.5rem;
}

.idx-main-logo {
  opacity: 1;
  padding-top: 3px;
}
.idx-main-logo:hover {
  cursor: pointer;
}

.idx-logo {
  opacity: 0.5;
  padding-top: 2px;
}

.idx-logo:hover {
  cursor: pointer;
}



@media (max-width: 1100px) {
  .vin-submit {
    height: 2rem;
  }
}
