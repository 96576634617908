.sbt-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 15%;
  height: 2.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  opacity: 0.8;
  font-size: 0.8rem;
  margin: 1.5rem;
}

.vin-gen-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 65%;
  height: 1.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  opacity: 0.8;
  font-size: 0.8rem;
  margin: 1rem;
}
