@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
.time_pick .custom-range-picker {
  background-color: #1b3643;
  height: 2.5rem;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.time_pick .custom-range-picker .ant-picker-input input::placeholder {
  color: white;
  opacity: 0.8;
}
.custom-range-picker .ant-picker-dropdown {
  background-color: #1b3643;
}

.time_pick .ant-picker .ant-picker-input > input {
  color: white;
  border: none;
  width: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  padding-left: 0.5rem;
}

.ant-form-item .ant-form-item-label > label {
  color: white;
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #fff;
}

.ant-picker .ant-picker-suffix {
  color: #fff;
}
.time_pick {
  display: flex;
  flex-direction: column;
  color: white;
  width: 95%;
  height: 100%;
  row-gap: 0.5rem;
}

.temp_pick {
  display: grid;
  grid-template-columns: 0.55fr 1fr;
  height: 2.5rem;
}
.anticon svg {
  color: white;
}

.ant-form-item {
  margin-bottom: 0;
  height: 2rem;
}
.time_pick .ant-picker {
  border: none;
  font-family: "Montserrat", sans-serif;
  height: 100%;
  border-radius: 13px;
}
.warning {
  color: rgb(255, 85, 85);
}

.ant-radio-wrapper {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.ap_title {
  color: white;
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
}

.ant-picker-dropdown .ant-picker-header-view {
  flex: auto;
  font-weight: 600;
  line-height: 40px;
  color: white;
}
.ant-picker-dropdown .ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.88);
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  background: #1b3643;
  color: white;
}
.ant-picker-dropdown .ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
  color: white;
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  background: #1b3643;
  color: white;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: white;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 8px 18px;
  color: white;
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-panel
  .ant-picker-content,
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
  color: white;
}
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 8px 18px;
  background-color: #1b3643;
  color: white;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: #fff;
  background: rgba(29, 152, 152, 0.29);
}
.ant-picker-content .th {
  color: white;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  width: 36px;
  color: white;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  transition: background 0.2s, border 0.2s;
  color: white;
}

.ant-picker-dropdown .ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-cell-inner:hover {
  background-color: #1b3643;
}

.ant-picker-dropdown .ant-picker-year-panel .ant-picker-body,
.ant-picker-dropdown .ant-picker-quarter-panel .ant-picker-body,
.ant-picker-dropdown .ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
  background-color: #1b3643;
}

@media (max-width: 1100px) {
  .time_pick .ant-picker {
    border: none;
    font-family: "Montserrat", sans-serif;
    height: 2rem;
    border-radius: 13px;
  }
}
