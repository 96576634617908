/* Sidebar styling */
.sidebar {
    background-color: #15202F;
    color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 30px 0 0;
    width: 11.2%;
    justify-content: center;
    flex: 1 1;
    z-index: 10;
    overflow: hidden;
}

/* General list styling */
.list {
    padding: 0;
    padding-top: 2rem;
    top: 25px;
    bottom: 25px;
    text-align: center;
}

/* Styling for all list items */
.main-home {
    list-style: none;
    backdrop-filter: blur(54px);
    border-radius: 6px 0px 0px 6px;
    transition: background-color 0.3s ease, border-left 0.3s ease, opacity 0.3s ease;
}

.a:hover {
    opacity: 1;
    background-color: #2E2441;
    border-left: 8px solid #F7931D;
    border-right: 2px solid #FFF;
    cursor: pointer;
    border-radius: 6px 0px 0px 6px;

}


/* Active (selected) item styles */
.main-home:hover {
    background-color: #2E2441;
    border-left: 8px solid #F7931D;
    opacity: 1;
    border-radius: 6px 0px 0px 6px;

}
.main-home {
    background-color: #2E2441;
    border-left: 8px solid #F7931D;
    color: #F7931D;
    border-radius: 6px 0px 0px 6px;

}

/* Styling for list items (spacing, icons, etc.) */
#list_item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 4%;
}

/* Styling for text inside list items */
h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
}

/* Subheading items inside expanded vehicle sections */
.vehicle-item {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
}

/* Styling for subheading items */
.sub-headings {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1rem;
    font-size: 0.8rem;
    cursor: pointer;
}

/* Hover effect on subheadings */
.sub-headings:hover {
    background-color: #333;
}

/* Styling for selected subheading item */
.active-sub {
    background-color: #2E2441;
    border-left: 8px solid #F7931D;
    color: #F7931D;
}

/* Styling for link in subheading */
a{
    text-decoration: none;
    color: white;
    font-weight: 300;
}

/* Responsive Design */
@media (max-width: 1400px) {
    h3 {
        font-size: 11px;
    }
    h4 {
        font-size: 10px;
    }
    .sub-headings {
        gap: 0.2rem;
        padding-left: 0.2rem;
    }
    .icon-navs {
        width: 1rem;
    }
}

@media (max-height: 650px) {
    /* You can add specific styles here if needed */
}