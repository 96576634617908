@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");

.ind-title {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: white;
  background: #15202f;
  top: 0;
  margin: 0;
  padding-left: 100%;
  font-family: "Lato", sans-serif;
  height: 55px;
  z-index:50;
}
.ind-icons {
  display: inline-flex;
  flex: 1;
}

.home-setting {
  padding-left: 70%;
  padding-right: 0;
  display: flex;
  flex: 1;
  float: right;
  color: #fff;
}
.ind-profile {
  position: absolute;
  left: 95%;
  float: right;
  opacity: 0.8;
}

.home-setting:hover {
  opacity: 1;
  cursor: pointer;
}
.profile:hover {
  opacity: 1;
  cursor: pointer;
}
.logo {
  padding-right: 10px;
}
.text {
  text-align: center;
}

.dropdown-menu {
  transition: width;
  position: absolute;
  width: 15%;
  height: 17rem;
  left: 77%;
  top: 8%;
  background: #142329;
  opacity: 1;
  border-radius: 0px 0px 4px 4px;
  z-index: 15;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.dropdown-items {
  font-family: "Mulish", sans-serif;
  margin: 0.7rem;
}
.profile-img {
  z-index: 100;
}

.ind-profile:hover {
  cursor: pointer;
  opacity: 1;
}


.ind-drop {
  position: absolute;
  width: 9rem;
  background: rgb(0, 0, 0, 0.8);
  top: 10%;
  right: 1%;
  z-index: 50;

}
  .ind-drop * {
    padding: 7%;
    cursor: pointer;
    opacity: 0.7;
  }
  .ind-drop *:hover {
    opacity: 1;
  }

  .ind-settings {
    cursor: pointer;
    opacity: 0.8;
  }

  .ind-settings:hover {
    opacity: 1;
  }

