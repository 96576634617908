@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.filter-option {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 1%;
}

.hide_toggle {
    color: white;
    right: 3%;
    top: 7%;
    font-size: 1.2rem;
}

.hide_toggle:hover {
    opacity: 1;
}

.custom_date {
    background-color: #1B3643;
    border: none;
    width: 60%;
    height: 2rem;
}

.log-filter-buttons {
    justify-content: end;
    width: 100%;
    display: flex;
    gap: 2%;
    padding-top: 1%;
    align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: #1B3643;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.ant-select-selection-placeholder {
    color: white;
}

.ant-select-multiple .ant-select-selection-placeholder {
    color: white;
    border: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: white;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #1B3643;
}

.log-filter-search {
    height: 2rem;
    background: linear-gradient(180deg, #00B7A8 0%, #044646 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    opacity: 0.8;
    color: white;
    font-family: 'Montserrat', sans-serif;
}
.log-filter-search:hover {
    opacity: 1;
    cursor: pointer;
}
span {
    font-family: 'Montserrat', sans-serif;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-family: 'Montserrat', sans-serif;
    color: white;
}
.coll-filter-name {
    width: 100%;
    display: flex;
    width: 100%;
    display: flex;
    gap: 5%;
    padding-left: 10%;
    cursor: pointer;
}

.coll-filter-name > * {
    border: 1px solid grey;
    border-radius: 15px;
    background-color: #1B3643;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 2%; */
    padding-left: 2%;
    padding-right: 2%;

}

.ant-select-disabled:where(.css-dev-only-do-not-override-w8mnev).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #1B3643;
}

.reset_options {
    display: flex;
    align-items: center;
    gap: 8%;
    font-size:1rem;
    cursor: pointer;
    opacity: 0.8;
}

.reset_options:hover {
    opacity: 1;
}

.refresh-logs {
    display: flex;
    gap: 2%;
    align-items: center;
    width: 100%;
    justify-content: end;
    cursor: pointer;
    opacity: 0.8;
}

.refresh-logs:hover {
    opacity: 1;
}

.ant-picker-input {
    color: white;
}
