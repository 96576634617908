.logbody-main {
    position: absolute;
    inset: 0;
    top: 13%;
    bottom: 2%;
    color: white;
    left: 8%;
}

.search-bar {
    position: absolute;
    inset: 0;
    height: 25%;
    display: flex;
    justify-content: center;

}
.logbody-heading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.log-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

table {
    border-collapse: separate;
    border-spacing: 10px; 
    text-align: center;
  }
  
  td {
    padding: 10px; 
    height: 2rem;
    width: 5rem;
  } 
  th {
   height: 2rem;
    width: 5rem;
  }
  tr {
    gap: 5%;
  }

  .custom-scrollbars__content {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .custom-scrollbars__content::-webkit-scrollbar {
    display: none;
  }
  
  .log-table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.7;
  }
  
  .log-table::-webkit-scrollbar-track {
    background: #454545;
    border-radius: 10px;
    opacity: 0.7;
  }
  
  .log-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1effed;
    opacity: 0.7;
  }
  .log-table::-webkit-scrollbar-track:hover {
    opacity: 1;
  }

  .logs-headings {
    width: 90%;
    display: grid;
    gap: 2rem;
    color: white;
    padding: 1%;
  }

  .logs-dashboard-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }