@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.vd-second-page {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    row-gap: 2rem;
    /* position: absolute; */
    /* top: 3rem; */
    width: 78%;
    /* left: 14%; */
    height: 70%;
}
.vd-value {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    margin-left: 5rem;
}
.vd-name {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.07em;
    display: flex;
    color: #FFFFFF;
}

.horizontal-line {

border: 0.5px solid #5F5F5F;
}
.slider-data {
    position: absolute;
    top: 8%;
    display: flex;
    flex-direction: column;
    left: 12%;
    width: 64%;
}
.slide-details {
    display: flex;
    position: relative;
    width: 100%;
    height: 1.5rem;
    border-radius: 10px;
    background: rgba(48, 48, 48, 0.6);
    margin-top: 1rem;
}

.coloured-part {
    width: 25%;
    background: #2C988F;
    border-radius: 10px 0px 0px 10px;
}

.slider-title {
    margin-left: 23%;
}

.left-indicator {
    position: absolute;
    left: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
}

.right-indicator {
    position: absolute;
    right: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
}
.good {
    position: absolute;
    top: 2rem;
    left: 1rem;
    font-size: small;
}
.bad {
    position: absolute;
    top: 2rem;
    right: 1rem;
    font-size: small;
}
.to-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
    flex-direction: column;
}
.first-to-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 27%;
}
.first-map {
    width: 100%;
    padding-top: 1%;
}
.select-parameter {
    position: absolute;
    top: 0;
    width: 5%;
    height: 100%;
    right: 0%;
    background: rgba(0, 0, 0, 0.22);
    border-radius: 0px 15px 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
}

#text {
    padding: 15px;
    writing-mode: vertical-lr;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
}
.select-option {
    position: absolute;
    top: 0;
    width: 15%;
    height: 100%;
    right: 0%;
    background: rgba(0, 0, 0, 0.22);
    border-radius: 0px 15px 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
}

.parameter-option {
    padding: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
.options {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0.5rem;
    font-size: 14px;
    overflow-y: scroll;
    justify-content: flex-start;
    align-items: center;
}
.parameter-option:hover {
    cursor: pointer;
    background: rgba(29, 152, 152, 0.29);
}

.prm_img_close {
    position: absolute;
    top: 7%;
    left: 2%;
}

.prm_img_close:hover {
    cursor: pointer;
}
.prm_img_open:hover {
    cursor: pointer;
}
.selected-param {
    opacity: 0.5;
    padding: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
.selected-param:hover {
    cursor: defaerror-tablet;
}
.line {
    position: absolute;
    border: 0.5px solid #5F5F5F;
    width: 70%;
    top: 35%;
    left: 9%;
}
.pop-up {
    height: 40%;
    width: 34%;
    background: aqua;
    top: 26%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 100;
    position: fixed;
    border-radius: inherit;
    background: rgba(0, 0, 1, 1);
    left: 35%;
    right: 28%;
    top: 25%;
    /* position: absolute; */
    flex-direction: column;
    gap: 4rem;
    padding-left: 2%;

}
.cross {
    position: absolute;
    top: 5%;
    right: 2%;
}
.cross:hover {
    cursor: pointer;
}

.error-title {
    position: absolute;
    font-family: 'Source Sans Pro';
    top: 5%;
    left: 6%;
    font-size: large;
}
.vd-name-new {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.07em;
    display: flex;
    color: #1EFFED;
}

.vd-name-new:hover {
    cursor: pointer;
    color: #1EFFED;
}

.error-table {
    display: grid;
    width: 87%;
    grid-template-columns: 1fr 1fr ;
    position: absolute;
    top: 18%;
    gap: 1rem;
    overflow-y: scroll ;
    height: 80%;
}
.table-heading {
    font-weight: bold;
}
.table-content{
    color: white;
}
.custom-scrollbars__content {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
.custom-scrollbars__content::-webkit-scrollbar {
    display: none;
  }

  .table-list::-webkit-scrollbar {
    width: 5px;
    opacity: 0.7;
}

.table-list::-webkit-scrollbar-track {
    background: #454545;
    border-radius: 10px;
    opacity: 0.7;
}

.table-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1EFFED;
    opacity: 0.7;
}
.table-list::-webkit-scrollbar-track:hover {
  opacity: 1;
}

.table-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    overflow-y: scroll;
  }
  
  .table-list-row {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 1rem;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
  color: white;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: white;
}
.ant-carousel .slick-dots li button {
    display: none;
}
.ant-carousel .slick-prev{
    margin-top:7rem;
    margin-left: 12rem;
}
.ant-carousel .slick-next {
    margin-top: 7rem;
    margin-right: 12rem;
}
.Carousel{
    width: 25rem;
}
/* .anticon svg{
    color: black;
} */



  @media (max-width: 1100px) {
    .options {
        font-size: 10px ;
    }
    .select-option {
        font-size: 0.8rem;
    }
  }